import { Button, Result } from 'antd';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import useSignOut from '../../hooks/use-sign-out';

const BootstrapMessage = ({ title, subTitle }: { title?: string, subTitle?: string }) => {
  const [t] = useTranslation();
  const signOut = useSignOut();
  const ref = useRef<NodeJS.Timeout | null>(null);

  const clearTimer = () => {
    if (ref.current) {
      clearTimeout(ref.current);
    }
  };

  useEffect(() => clearTimer, []);

  return (
    <Result
      style={{ width: '100%' }}
      status="404"
      title={title}
      subTitle={subTitle}
      extra={(
        <Button
          type="primary"
          ghost
          onClick={async () => {
            signOut();
            ref.current = setTimeout(() => {
              clearTimer();
              location.reload();
            }, 500);
          }}
        >
          {t('Logout')}
        </Button>
      )}
    />
  );
};

export default BootstrapMessage;
