import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";

const config = {
  environment: process.env.NODE_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  release: `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`,
  // We recommend adjusting this value in production, or using tracesSampler for finer control
  tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
    ? Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE)
    : undefined,
};

Sentry.init(config);
