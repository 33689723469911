import firebase from 'firebase/app';
import 'firebase/auth'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_APIKEY,
  authDomain: process.env.REACT_APP_FB_DOMAIN,
  storageBucket: process.env.REACT_APP_FB_STORAGE,
  databaseURL: process.env.REACT_APP_FB_URL
}

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export const FIREBASE_AUTH = firebase.auth();