import React, { FC, useEffect } from 'react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider as ReduxStoreProvider } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import store from './redux/store';
import client from './client';
import ThemeProvider from './components/theme-provider';
import Bootstrap from './screens/bootstrap';
import useConfigureMomentLocale from './hooks/use-configure-moment-locale';
import { AppOverlaySpinnerProvider } from './components/app-overlay-spinner';
import AntDConfigProvider from './components/antd-config-provider';
import I18NLanguageDetector from './components/i18n/language-detector';
import './themes/light.less';

const App: FC = () => {
  const [t] = useTranslation();
  useConfigureMomentLocale();

  useEffect(() => {
    const t = setTimeout(() => {
      clearTimeout(t);
      document.getElementById('splash')?.remove();
    }, 500);
  }, []);

  return (
    <ReduxStoreProvider store={store}>
      <ApolloProvider client={client}>
        <ThemeProvider>
          <div
            className="app"
            style={{ height: '100%', display: 'flex' }}
            data-testid="app"
          >
            <Helmet>
              <title>{t('AppName')}</title>
            </Helmet>
            <Router>
              <AntDConfigProvider>
                <Bootstrap />
              </AntDConfigProvider>
            </Router>
          </div>
          <AppOverlaySpinnerProvider />
          <I18NLanguageDetector />
        </ThemeProvider>
      </ApolloProvider>
    </ReduxStoreProvider>
  );
};

export default App;
