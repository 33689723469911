import React, { FC } from 'react';
import { Card, Avatar, Typography, Button } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { Organization } from '../../generated/types';

const { Title } = Typography;

type TOrganizationView = {
  organization: Organization,
  onSelect: (organization: Organization) => void,
}

const OrganizationView: FC<TOrganizationView> = ({ organization, onSelect }) => (
  <Button
    ghost
    onClick={() => { onSelect(organization) }}
    style={{
      height: 'auto',
      width: '100%',
      padding: 0,
      boxShadow: 'none',
    }}
  >
    <Card size="small">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'stretch',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Avatar
            size={80}
            src={organization.settings?.defaultProfileImage}
          />
          <Title
            className="z-margin-left"
            level={3}
            style={{
              marginBottom: 0,
              marginRight: '24px',
            }}
          >
            {organization.name}
          </Title>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="z-padding"
        >
          <RightOutlined style={{ fontSize: '24px' }} />
        </div>
      </div>
    </Card>
  </Button>
);

export default OrganizationView;
