import { IState } from './types';

type TAction = {
  type: string;
  payload: any;
};

const reducer = (state: IState | undefined, action: TAction) => ({
  ...(state ?? {}),
  ...action?.payload,
});

export default reducer;
