import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from "i18next-http-backend";

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    ns: ['common'],
    defaultNS: 'common',
    debug: process.env.NODE_ENV === "development",
    interpolation: {
      escapeValue: false,
    },
    lng: 'en',
    parseMissingKeyHandler: (key) => `${key}--missing`,
    react: {
      wait: true,
    },
    returnObjects: true,
  });

export const supportedLanguages = ['en', 'nl'];

export default i18n;
