import { Button, Result, Typography } from 'antd';
import React, { FC, lazy, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { StopOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

import BootstrapContext from '../../bootstrap-context';
import { resetConnections } from '../../client';
import AppOverlaySpinner from '../../components/app-overlay-spinner';
import SelectOrganization from '../../components/select-organization';
import { FIREBASE_AUTH } from '../../configs/firebase-config';
import { Organization, User } from '../../generated/types';
import {
    OrganizationDocument, OrganizationQuery, OrganizationQueryVariables
} from '../../graphql/queries/organization';
import useAuth from '../../hooks/use-auth';
import useLoggedInUser from '../../hooks/use-logged-in-user';
import useSelectedLocationId from '../../hooks/use-selected-location-id';
import useSelectedOrganization from '../../hooks/use-selected-organization';
import useSignOut from '../../hooks/use-sign-out';
import { getDefaultState } from '../../redux/store';
import useActionDispatch from '../../redux/use-action-dispatch';
import useShallowSelector from '../../redux/use-shallow-selector';
import BootstrapMessage from './bootstrap-message';
import isOrganizationSetup from './is-organization-setup';
import OrganizationNotSetupMessage from './organization-not-setup-message';
import queryUser from './query-user';

const Main = lazy(() => import('../main'));
const Authenticate = lazy(() => import('../authenticate'));
const Onboarding = lazy(() => import('../onboarding'));

const UnauthorizedAccessErrorCode = 'UnauthorizedAccess';

type TState = {
  bootstrapping?: boolean;
  userNotAssociatedWithOrganization?: boolean;
  userNeedToSelectFromMultipleOrganizations?: boolean;
  organizationNotSetup?: boolean;
  error?: any;
  userEmailNotVerified?: boolean;
};

const initialState: TState = {
  bootstrapping: false,
  userNotAssociatedWithOrganization: false,
  userEmailNotVerified: false,
  userNeedToSelectFromMultipleOrganizations: false,
  organizationNotSetup: false,
  error: null,
};

const Bootstrap: FC = () => {
  const [t] = useTranslation();
  const signOut = useSignOut();
  const history = useHistory();
  const client = useApolloClient();
  const rebootTs = useShallowSelector(({ rebootTs }) => rebootTs);
  const dispatch = useActionDispatch();
  const [state, setState] = useState(initialState);
  const ref = useRef({ wasLoggedIn: false });
  const { loading: loadingFBAuthState, isLoggedIn, user: fbUser } = useAuth();
  const loggedInUserInStore = useLoggedInUser();
  const selectedOrganizationInStore = useSelectedOrganization();
  const selectedLocationIdInStore = useSelectedLocationId();

  const getOrganizationIdToSelect = (user: User): string | null | undefined => {
    let selectedOrganizationId = null;
    const rolesByOrganizations = user.rolesByOrganizations || [];
    const organizationFoundForIdFromStore = selectedOrganizationInStore
      ? rolesByOrganizations.find((it) => it?.id === selectedOrganizationInStore?.id)
      : undefined;
    if (organizationFoundForIdFromStore) {
      selectedOrganizationId = organizationFoundForIdFromStore.id;
    } else if (rolesByOrganizations.length === 1) {
      // attached to single org
      selectedOrganizationId = rolesByOrganizations[0]?.id;
    }
    return selectedOrganizationId;
  };

  const selectOrganization = async (organizationId: string) => {
    if (fbUser) {
      dispatch({ selectedOrganization: null });
      setState({ bootstrapping: true });
      resetConnections();

      try {
        const result = await client
          .query<OrganizationQuery, OrganizationQueryVariables>({
            query: OrganizationDocument,
            context: {
              headers: {
                'X-ORG-ID': organizationId
              }
            },
            fetchPolicy: 'no-cache',
          });
        const organization = result?.data?.organization as Organization;
        const locations = organization?.locations ?? [];
        const firstLocation = locations.length > 0 ? locations[0]?.id : null;

        if (isOrganizationSetup(organization)) {
          const user = await queryUser(client, fbUser, organization?.id);
          const selectedLocationExists = selectedLocationIdInStore
            && !!locations.find((it) => it?.id === selectedLocationIdInStore);

          dispatch({
            loggedInUser: user,
            selectedOrganization: organization,
            selectedLocationId: selectedLocationExists ? selectedLocationIdInStore : firstLocation,
          });
        } else {
          setState({
            bootstrapping: false,
            organizationNotSetup: true,
          });
        }
      } catch (error: any) {
        if (error?.graphQLErrors?.find((it: any) => it?.name?.toLowerCase() === 'UnAuthorisedError'.toLowerCase())) {
          setState({ bootstrapping: false, error: { name: UnauthorizedAccessErrorCode } });
        } else {
          setState({ bootstrapping: false, error });
        }
      }
    }
  };

  const switchOrganization = async (organizationId: string) => {
    if (selectedOrganizationInStore?.id !== organizationId) {
      await selectOrganization(organizationId);
    }
  };

  useEffect(() => {
    if (fbUser?.emailVerified) {
      const bootStrap = async () => {
        setState({ bootstrapping: true });
        const user = await queryUser(client, fbUser);
        dispatch({ loggedInUser: user });
        if (user) {
          const rolesByOrganizations = user.rolesByOrganizations || [];
          if (rolesByOrganizations.length === 0) {
            setState({
              bootstrapping: false,
              userNotAssociatedWithOrganization: true,
            });
          } else {
            const selectedOrganizationId = getOrganizationIdToSelect(user);
            if (selectedOrganizationId) {
              selectOrganization(selectedOrganizationId);
            } else if (rolesByOrganizations.length > 1) {
              setState({
                bootstrapping: false,
                userNeedToSelectFromMultipleOrganizations: true,
              });
            }
          }
        }
      };
      bootStrap();
    }
  }, [fbUser?.uid, rebootTs]);

  useEffect(() => {
    if (loggedInUserInStore && selectedOrganizationInStore) {
      setState({ bootstrapping: false });
    }
  }, [loggedInUserInStore?.id, selectedOrganizationInStore?.id]);

  useEffect(() => {
    if (!isLoggedIn) {
      if (loggedInUserInStore) {
        dispatch(getDefaultState());
      }
      setState(initialState);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const unregisterAuthObserver = FIREBASE_AUTH.onAuthStateChanged(user => {
      const isLoggedIn = !!user;
      if (!isLoggedIn && ref.current.wasLoggedIn) {
        history.push('/');
      }
      ref.current.wasLoggedIn = isLoggedIn;
    })
    return () => unregisterAuthObserver()
  }, []);

  if (loadingFBAuthState || state.bootstrapping) {
    return <AppOverlaySpinner />;
  }

  if (state.error) {
    if (state.error.name === UnauthorizedAccessErrorCode) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
          }}
        >
          <Result
            status="error"
            title={<Typography.Text type="danger">{t('UnauthorizedAccess.Title')}</Typography.Text>}
            subTitle={<Typography.Text type="danger">{t('UnauthorizedAccess.Subtitle')}</Typography.Text>}
            icon={<StopOutlined />}
            extra={(
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  type="primary"
                  onClick={() => signOut()}
                >
                  {t('common:Logout')}
                </Button>
              </div>
            )}
          />
        </div>
      );
    }
    return <BootstrapMessage title={t('Bootstrap.SomethingWentWrong')} />;
  }

  if (state.userNotAssociatedWithOrganization) {
    return <Onboarding />;
  }

  if (state.organizationNotSetup) {
    return <OrganizationNotSetupMessage />;
  }

  if (state.userNeedToSelectFromMultipleOrganizations) {
    return (
      <BootstrapContext.Provider value={{ switchOrganization }}>
        <SelectOrganization />
      </BootstrapContext.Provider>
    );
  }

  return isLoggedIn && loggedInUserInStore
    ? (
      <BootstrapContext.Provider value={{ switchOrganization }}>
        <Main />
      </BootstrapContext.Provider>
    )
    : <Authenticate />;
};

export default Bootstrap;
