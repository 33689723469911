import {
  ApolloClient, InMemoryCache,
  ApolloLink, split
} from '@apollo/client';
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { createUploadLink } from 'apollo-upload-client';
import { captureException } from '@sentry/react';
import store from './redux/store';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { FragmentDefinitionNode, OperationDefinitionNode } from 'graphql';
import getFbUserToken from './utils/auth/get-fb-user-token';

const cache = new InMemoryCache({ addTypename: false });

const authLink = setContext(async (_, { headers, ...context }) => {
  const token = await getFbUserToken();
  const persistedState = store.getState();
  const { selectedOrganization } = persistedState || {};
  
  const { id: orgId } = selectedOrganization || {};
  return {
    ...context,
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      'X-APP-TYPE': 'APP', // need have constants on UI side
      ...(orgId && { 'X-ORG-ID': orgId }),
    },
  };
});

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_API_END_POINT,
});

const errorLink = onError(({
  graphQLErrors, networkError, forward, operation
}) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, }) =>
      console.error(`[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(path)}`));
    captureException(new Error(`[GraphQL error]: ${JSON.stringify(graphQLErrors)} Operation: ${JSON.stringify(operation)}`));
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.error(`[Network error]: ${JSON.stringify(networkError)} Operation: ${JSON.stringify(operation)}`);
    captureException(new Error(`[Network error]: ${JSON.stringify(networkError)} Operation: ${JSON.stringify(operation)}`));
  }
  return forward(operation);
});

const WS_END_POINT = process.env.REACT_APP_SUBSCRIPTION_END_POINT || "http://localhost:8080/graphql";

const wsClient = new SubscriptionClient(WS_END_POINT, {
  reconnect: true,
  reconnectionAttempts: 50,
  lazy: true,
  timeout: 20000,
  async connectionParams() {
    const persistedState = store.getState();
    const { selectedOrganization } = persistedState || {};
    const token = await getFbUserToken();
    return {
      authToken: token,
      appType: 'APP', // need have constants on UI side
      selectedOrganization: selectedOrganization?.id
    };
  },
});

const wsLink = new WebSocketLink(wsClient);

// using the ability to split links, you can send data to each link
// depending on what kind of operation is being sent
const link = split(
  // split based on operation type
  ({ query }) => {
    const definition: OperationDefinitionNode | FragmentDefinitionNode = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition?.operation === 'subscription';
  },
  wsLink,
  uploadLink,
);


const client = new ApolloClient({
  // Required constructor fields
  link: ApolloLink.from([errorLink, authLink, link]),
  cache,
  // Optional constructor fields
  queryDeduplication: false,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache'
    }
  },
});

export const resetConnections = () => {
  wsClient.close();
};

export default client;