import React from 'react';
import ReactDOM from 'react-dom';
import styles from './styles.module.less';

const AppOverlaySpinnerView: React.FC<{ visible: boolean }> = ({ visible }) => (
  ReactDOM.createPortal((
    <div className={`${styles.zLoader} z-bg-primary-2 ${visible ? styles.zLoaderActive : ''}`}>
      <div className={`${styles.zLoaderBar} z-bg-primary-5 z-linear-gradient-primary ${visible ? styles.zLoaderBarActive : ''}`} />
    </div>
  ), document.body)
);

export default React.memo(AppOverlaySpinnerView);
