import { FIREBASE_AUTH } from '../configs/firebase-config';
import { getDefaultState } from '../redux/store';
import useActionDispatch from '../redux/use-action-dispatch';
import detectTheme from '../utils/detect-theme';

const useSignOut = () => {
  const dispatch = useActionDispatch();

  const signOut = async () => {
    dispatch({
      ...getDefaultState(),
      theme: detectTheme() ?? 'zaycare',
    });
    return FIREBASE_AUTH.signOut();
  };

  return signOut;
};

export default useSignOut;
