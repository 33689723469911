import { FC, useEffect } from 'react';
import { publish } from 'pusu';
import publication from '../../publications/app-overlay-spinner-queue';

const AppOverlaySpinner: FC = () => {
  useEffect(() => {
    publish(publication, 'add');
    return () => {
      publish(publication, 'delete');
    };
  }, []);

  return null;
};

export default AppOverlaySpinner;
