import * as SchemaTypes from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type TimingFieldsFragment = (
  { __typename?: 'Timing' }
  & Pick<SchemaTypes.Timing, 'id' | 'name' | 'checkIn' | 'checkOut' | 'settingsType' | 'active' | 'billedHours' | 'monthlyBilledHours' | 'editable' | 'hours'>
);

export type WeekdaysFieldsFragment = (
  { __typename?: 'WeekDay' }
  & Pick<SchemaTypes.WeekDay, 'id' | 'dayNumber' | 'active' | 'timings'>
);

export type HolidaysFieldsFragment = (
  { __typename?: 'Holiday' }
  & Pick<SchemaTypes.Holiday, 'id' | 'name' | 'startDate' | 'endDate' | 'type' | 'repeat' | 'active' | 'editable' | 'settingsType'>
);

export type BillingFieldsFragment = (
  { __typename?: 'BillingSetting' }
  & Pick<SchemaTypes.BillingSetting, 'billingInterval' | 'billingPlan' | 'billingCycle' | 'invoiceGenDate' | 'invoiceEmailDueDays' | 'dueDays' | 'paymentOptions' | 'autoDebit' | 'accountantEmails'>
  & { accounts?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'BankAccount' }
    & Pick<SchemaTypes.BankAccount, 'id' | 'name' | 'number' | 'code'>
  )>>> }
);

export type LocationFieldsFragment = (
  { __typename?: 'Location' }
  & Pick<SchemaTypes.Location, 'id' | 'type' | 'name' | 'shortName' | 'longName' | 'regNumber' | 'phoneNumber' | 'email' | 'website'>
  & { address?: SchemaTypes.Maybe<(
    { __typename?: 'Address' }
    & Pick<SchemaTypes.Address, 'address' | 'address1' | 'postalCode'>
  )>, contactPerson?: SchemaTypes.Maybe<(
    { __typename?: 'Parent' }
    & Pick<SchemaTypes.Parent, 'name'>
  ) | (
    { __typename?: 'Teacher' }
    & Pick<SchemaTypes.Teacher, 'name'>
  )>, fields?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'Field' }
    & Pick<SchemaTypes.Field, 'id' | 'value'>
  )>>>, settings?: SchemaTypes.Maybe<(
    { __typename?: 'Setting' }
    & Pick<SchemaTypes.Setting, 'extraDayBooking'>
    & { timings?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Timing' }
      & TimingFieldsFragment
    )>>>, weekdays?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'WeekDay' }
      & WeekdaysFieldsFragment
    )>>>, holidays?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Holiday' }
      & HolidaysFieldsFragment
    )>>>, billing?: SchemaTypes.Maybe<(
      { __typename?: 'BillingSetting' }
      & BillingFieldsFragment
    )>, teacherChildRatio?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'GroupRule' }
      & Pick<SchemaTypes.GroupRule, 'id' | 'minAge' | 'maxAge' | 'strict'>
      & { ratios?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
        { __typename?: 'GroupTeacherChildRatio' }
        & Pick<SchemaTypes.GroupTeacherChildRatio, 'childCount' | 'teachersCount' | 'actualChildCount' | 'actualTeacherCount'>
        & { rules?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
          { __typename?: 'GroupRule' }
          & Pick<SchemaTypes.GroupRule, 'id' | 'minAge' | 'maxAge' | 'strict'>
          & { ratios?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
            { __typename?: 'GroupTeacherChildRatio' }
            & Pick<SchemaTypes.GroupTeacherChildRatio, 'childCount' | 'teachersCount' | 'actualChildCount' | 'actualTeacherCount'>
          )>>> }
        )>>> }
      )>>> }
    )>>> }
  )>, groups?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'Group' }
    & Pick<SchemaTypes.Group, 'id' | 'name' | 'color' | 'minAge' | 'maxAge' | 'maxChildren'>
    & { settings?: SchemaTypes.Maybe<(
      { __typename?: 'Setting' }
      & { teacherChildRatio?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
        { __typename?: 'GroupRule' }
        & Pick<SchemaTypes.GroupRule, 'minAge' | 'maxAge' | 'strict'>
        & { ratios?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
          { __typename?: 'GroupTeacherChildRatio' }
          & Pick<SchemaTypes.GroupTeacherChildRatio, 'childCount' | 'teachersCount'>
          & { rules?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
            { __typename?: 'GroupRule' }
            & Pick<SchemaTypes.GroupRule, 'minAge' | 'maxAge' | 'strict'>
            & { ratios?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
              { __typename?: 'GroupTeacherChildRatio' }
              & Pick<SchemaTypes.GroupTeacherChildRatio, 'childCount' | 'teachersCount'>
            )>>> }
          )>>> }
        )>>> }
      )>>>, timings?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
        { __typename?: 'Timing' }
        & TimingFieldsFragment
      )>>>, weekdays?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
        { __typename?: 'WeekDay' }
        & WeekdaysFieldsFragment
      )>>>, holidays?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
        { __typename?: 'Holiday' }
        & HolidaysFieldsFragment
      )>>> }
    )> }
  )>>>, packages?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'Package' }
    & Pick<SchemaTypes.Package, 'id' | 'name' | 'unitAmount' | 'unitType' | 'billedHours' | 'type' | 'active' | 'currency' | 'lookupKey'>
  )>>>, schools?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'Location' }
    & Pick<SchemaTypes.Location, 'id' | 'name' | 'active'>
    & { settings?: SchemaTypes.Maybe<(
      { __typename?: 'Setting' }
      & Pick<SchemaTypes.Setting, 'schoolWeeks' | 'schoolVacationWeeks'>
      & { billedHours?: SchemaTypes.Maybe<(
        { __typename?: 'BilledHours' }
        & Pick<SchemaTypes.BilledHours, 'bso' | 'vso'>
      )> }
    )>, address?: SchemaTypes.Maybe<(
      { __typename?: 'Address' }
      & Pick<SchemaTypes.Address, 'address' | 'address1' | 'postalCode' | 'country'>
    )>, groups?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Group' }
      & Pick<SchemaTypes.Group, 'id' | 'name'>
    )>>> }
  )>>> }
);

export type OrganizationFieldsFragment = (
  { __typename?: 'Organization' }
  & Pick<SchemaTypes.Organization, 'id' | 'name' | 'type'>
  & { plan?: SchemaTypes.Maybe<(
    { __typename?: 'Plan' }
    & Pick<SchemaTypes.Plan, 'id'>
    & { features?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Feature' }
      & Pick<SchemaTypes.Feature, 'id' | 'name' | 'ordering'>
      & { authorisations?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
        { __typename?: 'Authorisation' }
        & Pick<SchemaTypes.Authorisation, 'id' | 'description'>
      )>>> }
    )>>> }
  )>, roles?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'AuthorizationByRole' }
    & Pick<SchemaTypes.AuthorizationByRole, 'id' | 'key' | 'name' | 'ordering' | 'userCount'>
    & { features?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Feature' }
      & Pick<SchemaTypes.Feature, 'id' | 'name' | 'ordering'>
      & { authorisations?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
        { __typename?: 'Authorisation' }
        & Pick<SchemaTypes.Authorisation, 'id' | 'key' | 'description'>
      )>>> }
    )>>> }
  )>>>, account?: SchemaTypes.Maybe<(
    { __typename?: 'Account' }
    & Pick<SchemaTypes.Account, 'chargesEnabled'>
  )>, documents?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'Document' }
    & Pick<SchemaTypes.Document, 'id' | 'name' | 'url'>
  )>>>, settings?: SchemaTypes.Maybe<(
    { __typename?: 'Setting' }
    & Pick<SchemaTypes.Setting, 'currency' | 'defaultProfileImage'>
    & { timings?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Timing' }
      & TimingFieldsFragment
    )>>>, weekdays?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'WeekDay' }
      & WeekdaysFieldsFragment
    )>>>, holidays?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Holiday' }
      & HolidaysFieldsFragment
    )>>>, billing?: SchemaTypes.Maybe<(
      { __typename?: 'BillingSetting' }
      & BillingFieldsFragment
    )>, authorisationByRole?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'AuthorizationByRole' }
      & Pick<SchemaTypes.AuthorizationByRole, 'id' | 'name' | 'key' | 'ordering'>
    )>>>, customFields?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Field' }
      & Pick<SchemaTypes.Field, 'id' | 'name' | 'primary' | 'required' | 'datatype' | 'entities' | 'localeKey' | 'multi'>
      & { possibleValues?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
        { __typename?: 'FieldPossibleValue' }
        & Pick<SchemaTypes.FieldPossibleValue, 'id' | 'name'>
      )>>> }
    )>>> }
  )>, locations?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'Location' }
    & LocationFieldsFragment
  )>>> }
);

export type OrganizationQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type OrganizationQuery = (
  { __typename?: 'Query' }
  & { organization?: SchemaTypes.Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  )> }
);

export type OrganizationUpdatedSubscriptionVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type OrganizationUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { updatedOrganization?: SchemaTypes.Maybe<(
    { __typename?: 'Organization' }
    & OrganizationFieldsFragment
  )> }
);

export type LocationUpdatedSubscriptionVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type LocationUpdatedSubscription = (
  { __typename?: 'Subscription' }
  & { locationUpdated?: SchemaTypes.Maybe<(
    { __typename?: 'Location' }
    & LocationFieldsFragment
  )> }
);

export type DiscountsQueryVariables = SchemaTypes.Exact<{
  locationId?: SchemaTypes.Maybe<SchemaTypes.Scalars['String']>;
  childId?: SchemaTypes.Maybe<SchemaTypes.Scalars['String']>;
}>;


export type DiscountsQuery = (
  { __typename?: 'Query' }
  & { discounts?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'Discount' }
    & Pick<SchemaTypes.Discount, 'id' | 'name' | 'currency' | 'duration' | 'amount' | 'percentage' | 'durationInMonths'>
    & { packages?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Package' }
      & Pick<SchemaTypes.Package, 'id' | 'name'>
    )>>> }
  )>>> }
);

export type TaxRatesQueryVariables = SchemaTypes.Exact<{
  all?: SchemaTypes.Maybe<SchemaTypes.Scalars['Boolean']>;
}>;


export type TaxRatesQuery = (
  { __typename?: 'Query' }
  & { taxRates?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'TaxRate' }
    & Pick<SchemaTypes.TaxRate, 'id' | 'name' | 'description' | 'inclusive' | 'percentage' | 'active'>
  )>>> }
);

export type RecentJobsQueryVariables = SchemaTypes.Exact<{
  types?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<SchemaTypes.JobType>>>;
  groupByLocation?: SchemaTypes.Maybe<SchemaTypes.Scalars['Boolean']>;
}>;


export type RecentJobsQuery = (
  { __typename?: 'Query' }
  & { recentJobsByType?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
    { __typename?: 'Job' }
    & Pick<SchemaTypes.Job, 'id' | 'type' | 'status' | 'date' | 'payload' | 'createdOn'>
  )>>> }
);

export const TimingFieldsFragmentDoc = gql`
    fragment TimingFields on Timing {
  id
  name
  checkIn
  checkOut
  settingsType
  active
  billedHours
  monthlyBilledHours
  editable
  hours
}
    `;
export const WeekdaysFieldsFragmentDoc = gql`
    fragment WeekdaysFields on WeekDay {
  id
  dayNumber
  active
  timings
}
    `;
export const HolidaysFieldsFragmentDoc = gql`
    fragment HolidaysFields on Holiday {
  id
  name
  startDate
  endDate
  type
  repeat
  active
  editable
  settingsType
}
    `;
export const BillingFieldsFragmentDoc = gql`
    fragment BillingFields on BillingSetting {
  billingInterval
  billingPlan
  billingCycle
  invoiceGenDate
  invoiceEmailDueDays
  dueDays
  paymentOptions
  autoDebit
  accounts {
    id
    name
    number
    code
  }
  accountantEmails
}
    `;
export const LocationFieldsFragmentDoc = gql`
    fragment LocationFields on Location {
  id
  type
  name
  shortName
  longName
  regNumber
  address {
    address
    address1
    postalCode
  }
  phoneNumber
  email
  website
  contactPerson {
    name
  }
  fields {
    id
    value
  }
  settings {
    timings {
      ...TimingFields
    }
    weekdays {
      ...WeekdaysFields
    }
    holidays {
      ...HolidaysFields
    }
    billing {
      ...BillingFields
    }
    extraDayBooking
    teacherChildRatio {
      id
      minAge
      maxAge
      strict
      ratios {
        childCount
        teachersCount
        actualChildCount
        actualTeacherCount
        rules {
          id
          minAge
          maxAge
          strict
          ratios {
            childCount
            teachersCount
            actualChildCount
            actualTeacherCount
          }
        }
      }
    }
  }
  groups {
    id
    name
    color
    minAge
    maxAge
    maxChildren
    settings {
      teacherChildRatio {
        minAge
        maxAge
        strict
        ratios {
          childCount
          teachersCount
          rules {
            minAge
            maxAge
            strict
            ratios {
              childCount
              teachersCount
            }
          }
        }
      }
      timings {
        ...TimingFields
      }
      weekdays {
        ...WeekdaysFields
      }
      holidays {
        ...HolidaysFields
      }
    }
  }
  packages {
    id
    name
    unitAmount
    unitType
    billedHours
    type
    active
    currency
    lookupKey
  }
  schools(all: true) {
    id
    name
    active
    settings {
      billedHours {
        bso
        vso
      }
      schoolWeeks
      schoolVacationWeeks
    }
    address {
      address
      address1
      postalCode
      country
    }
    groups {
      id
      name
    }
  }
}
    ${TimingFieldsFragmentDoc}
${WeekdaysFieldsFragmentDoc}
${HolidaysFieldsFragmentDoc}
${BillingFieldsFragmentDoc}`;
export const OrganizationFieldsFragmentDoc = gql`
    fragment OrganizationFields on Organization {
  id
  name
  type
  plan {
    id
    features {
      id
      name
      ordering
      authorisations {
        id
        description
      }
    }
  }
  roles {
    id
    key
    name
    ordering
    userCount
    features {
      id
      name
      ordering
      authorisations {
        id
        key
        description
      }
    }
  }
  account {
    chargesEnabled
  }
  documents {
    id
    name
    url
  }
  settings {
    timings {
      ...TimingFields
    }
    weekdays {
      ...WeekdaysFields
    }
    holidays {
      ...HolidaysFields
    }
    billing {
      ...BillingFields
    }
    authorisationByRole {
      id
      name
      key
      ordering
    }
    currency
    defaultProfileImage
    customFields {
      id
      name
      primary
      required
      datatype
      entities
      localeKey
      multi
      possibleValues {
        id
        name
      }
    }
  }
  plan {
    id
  }
  locations {
    ...LocationFields
  }
}
    ${TimingFieldsFragmentDoc}
${WeekdaysFieldsFragmentDoc}
${HolidaysFieldsFragmentDoc}
${BillingFieldsFragmentDoc}
${LocationFieldsFragmentDoc}`;
export const OrganizationDocument = gql`
    query organization {
  organization {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;

/**
 * __useOrganizationQuery__
 *
 * To run a query within a React component, call `useOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationQuery(baseOptions?: Apollo.QueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
        return Apollo.useQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, baseOptions);
      }
export function useOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OrganizationQuery, OrganizationQueryVariables>) {
          return Apollo.useLazyQuery<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, baseOptions);
        }
export type OrganizationQueryHookResult = ReturnType<typeof useOrganizationQuery>;
export type OrganizationLazyQueryHookResult = ReturnType<typeof useOrganizationLazyQuery>;
export type OrganizationQueryResult = Apollo.QueryResult<OrganizationQuery, OrganizationQueryVariables>;
export const OrganizationUpdatedDocument = gql`
    subscription organizationUpdated {
  updatedOrganization: organizationUpdated {
    ...OrganizationFields
  }
}
    ${OrganizationFieldsFragmentDoc}`;

/**
 * __useOrganizationUpdatedSubscription__
 *
 * To run a query within a React component, call `useOrganizationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OrganizationUpdatedSubscription, OrganizationUpdatedSubscriptionVariables>) {
        return Apollo.useSubscription<OrganizationUpdatedSubscription, OrganizationUpdatedSubscriptionVariables>(OrganizationUpdatedDocument, baseOptions);
      }
export type OrganizationUpdatedSubscriptionHookResult = ReturnType<typeof useOrganizationUpdatedSubscription>;
export type OrganizationUpdatedSubscriptionResult = Apollo.SubscriptionResult<OrganizationUpdatedSubscription>;
export const LocationUpdatedDocument = gql`
    subscription locationUpdated {
  locationUpdated {
    ...LocationFields
  }
}
    ${LocationFieldsFragmentDoc}`;

/**
 * __useLocationUpdatedSubscription__
 *
 * To run a query within a React component, call `useLocationUpdatedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useLocationUpdatedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationUpdatedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useLocationUpdatedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<LocationUpdatedSubscription, LocationUpdatedSubscriptionVariables>) {
        return Apollo.useSubscription<LocationUpdatedSubscription, LocationUpdatedSubscriptionVariables>(LocationUpdatedDocument, baseOptions);
      }
export type LocationUpdatedSubscriptionHookResult = ReturnType<typeof useLocationUpdatedSubscription>;
export type LocationUpdatedSubscriptionResult = Apollo.SubscriptionResult<LocationUpdatedSubscription>;
export const DiscountsDocument = gql`
    query discounts($locationId: String, $childId: String) {
  discounts(locationId: $locationId, childId: $childId) {
    id
    name
    currency
    duration
    amount
    percentage
    durationInMonths
    packages {
      id
      name
    }
  }
}
    `;

/**
 * __useDiscountsQuery__
 *
 * To run a query within a React component, call `useDiscountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscountsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      childId: // value for 'childId'
 *   },
 * });
 */
export function useDiscountsQuery(baseOptions?: Apollo.QueryHookOptions<DiscountsQuery, DiscountsQueryVariables>) {
        return Apollo.useQuery<DiscountsQuery, DiscountsQueryVariables>(DiscountsDocument, baseOptions);
      }
export function useDiscountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DiscountsQuery, DiscountsQueryVariables>) {
          return Apollo.useLazyQuery<DiscountsQuery, DiscountsQueryVariables>(DiscountsDocument, baseOptions);
        }
export type DiscountsQueryHookResult = ReturnType<typeof useDiscountsQuery>;
export type DiscountsLazyQueryHookResult = ReturnType<typeof useDiscountsLazyQuery>;
export type DiscountsQueryResult = Apollo.QueryResult<DiscountsQuery, DiscountsQueryVariables>;
export const TaxRatesDocument = gql`
    query taxRates($all: Boolean) {
  taxRates(all: $all) {
    id
    name
    description
    inclusive
    percentage
    active
  }
}
    `;

/**
 * __useTaxRatesQuery__
 *
 * To run a query within a React component, call `useTaxRatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaxRatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaxRatesQuery({
 *   variables: {
 *      all: // value for 'all'
 *   },
 * });
 */
export function useTaxRatesQuery(baseOptions?: Apollo.QueryHookOptions<TaxRatesQuery, TaxRatesQueryVariables>) {
        return Apollo.useQuery<TaxRatesQuery, TaxRatesQueryVariables>(TaxRatesDocument, baseOptions);
      }
export function useTaxRatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaxRatesQuery, TaxRatesQueryVariables>) {
          return Apollo.useLazyQuery<TaxRatesQuery, TaxRatesQueryVariables>(TaxRatesDocument, baseOptions);
        }
export type TaxRatesQueryHookResult = ReturnType<typeof useTaxRatesQuery>;
export type TaxRatesLazyQueryHookResult = ReturnType<typeof useTaxRatesLazyQuery>;
export type TaxRatesQueryResult = Apollo.QueryResult<TaxRatesQuery, TaxRatesQueryVariables>;
export const RecentJobsDocument = gql`
    query RecentJobs($types: [JobType], $groupByLocation: Boolean) {
  recentJobsByType(types: $types, groupByLocation: $groupByLocation) {
    id
    type
    status
    date
    payload
    createdOn
  }
}
    `;

/**
 * __useRecentJobsQuery__
 *
 * To run a query within a React component, call `useRecentJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentJobsQuery({
 *   variables: {
 *      types: // value for 'types'
 *      groupByLocation: // value for 'groupByLocation'
 *   },
 * });
 */
export function useRecentJobsQuery(baseOptions?: Apollo.QueryHookOptions<RecentJobsQuery, RecentJobsQueryVariables>) {
        return Apollo.useQuery<RecentJobsQuery, RecentJobsQueryVariables>(RecentJobsDocument, baseOptions);
      }
export function useRecentJobsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecentJobsQuery, RecentJobsQueryVariables>) {
          return Apollo.useLazyQuery<RecentJobsQuery, RecentJobsQueryVariables>(RecentJobsDocument, baseOptions);
        }
export type RecentJobsQueryHookResult = ReturnType<typeof useRecentJobsQuery>;
export type RecentJobsLazyQueryHookResult = ReturnType<typeof useRecentJobsLazyQuery>;
export type RecentJobsQueryResult = Apollo.QueryResult<RecentJobsQuery, RecentJobsQueryVariables>;