const detectTheme = (): string | null | undefined => {
  // TODO:
  // Detect the theme, which can be vendor specific
  // Priority order can be domain - querystring - cookie - local storage
  // e.g. vendorA.zaycare.com can have theme named 'vendorA'
  const params = new URLSearchParams(window.location.search)
  return params.get('theme');
};

export default detectTheme;
