import { Organization } from "../../generated/types";

const isOrganizationSetup = (organizationToCheck: Organization) => {
  // checks if locations length is equal to 1
  // checks if at least 1 group is set up in the location
  if (organizationToCheck) {
    const { locations = [] } = organizationToCheck;
    return locations && locations.length > 0;
  }
};

export default isOrganizationSetup;
