import React, { FC, ReactNode } from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { Card, Typography, Divider } from 'antd';

const { Text } = Typography;

type TFallbackProps = {
  error: Error
};

const Fallback: FC<TFallbackProps> = ({ error }: TFallbackProps) => {
  const [t] = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: 16,
      }}
    >
      <Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Text type="danger">{t('SomeErrorOccurred')}</Text>
          <Divider />
          <Text type="danger">{error.toString()}</Text>
        </div>
      </Card>
    </div>
  );
};

type TErrorBoundaryProps = {
  children: ReactNode
};

const ErrorBoundary: FC<TErrorBoundaryProps> = ({ children }: TErrorBoundaryProps) => (
  <Sentry.ErrorBoundary
    fallback={(args) => (<Fallback {...args} />)}
  >
    {children || null}
  </Sentry.ErrorBoundary>
);

export default ErrorBoundary;
