import React from 'react';
import { useTranslation } from 'react-i18next';
import useSelectedOrganization from '../../hooks/use-selected-organization';
import BootstrapMessage from './bootstrap-message';

const OrganizationNotSetupMessage = () => {
  const [t] = useTranslation();
  const organization = useSelectedOrganization();

  return organization
    ? <BootstrapMessage title={t('Bootstrap.OrganizationNotSetup', { name: organization.name })} />
    : <BootstrapMessage title={t('Bootstrap.SomethingWentWrong')} />;
};

export default OrganizationNotSetupMessage;
