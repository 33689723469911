import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Result, Space } from 'antd';
import { Organization } from '../../generated/types';
import useLoggedInUser from '../../hooks/use-logged-in-user';
import OrganizationView from './organization-view';
import BootstrapContext from '../../bootstrap-context';

const SelectOrganization: FC = () => {
  const [t] = useTranslation();
  const loggedInUser = useLoggedInUser();
  const { switchOrganization } = useContext(BootstrapContext);

  const handleSelectOrganization = async (organization: Organization) => {
    switchOrganization(organization?.id);
  };

  const renderOrganization = (organization: Organization | null) => (
    organization
      ? <OrganizationView key={organization.id} organization={organization} onSelect={handleSelectOrganization} />
      : null
  );

  return (
    <Row align="middle" justify="center" style={{ width: '100%' }}>
      <Col span={10}>
        <Result
          status="info"
          title={t('SelectOrganization.Title')}
          icon={<i className="far fa-building" style={{ fontSize: '120px' }} />}
          extra={(
            <Space size="small" direction="vertical" style={{ width: '100%' }}>
              {loggedInUser?.rolesByOrganizations?.map(renderOrganization)}
            </Space>
          )}
        />
      </Col>
    </Row>
  );
};

export default SelectOrganization;
