import { createStore } from 'redux';
import detectTheme from '../utils/detect-theme';
import { DEFAULT_THEME } from '../constants/theme';
import reducer from './reducer';
import { IState, ILocalStorageState } from './types';
import action from './action';
import getLanguage from '../utils/get-language';

const ZAYCARE_PERSISTENT_STATE = 'ZAYCARE_PERSISTENT_STATE';

export function getDefaultState() {
  return {
    loggedInUser: null,
    selectedOrganization: null,
    selectedLocationId: null,
    hiddenInvoiceColumns: [],
    rebootTs: null,
    theme: detectTheme() ?? DEFAULT_THEME,
    zone: getLanguage() ?? 'en',
    language: getLanguage() ?? 'en',
    tinkCode: null,
  };
}

function saveToLocalStorage(state: IState) {
  try {
    const stateToPersist: ILocalStorageState = {
      theme: state.theme,
      hiddenInvoiceColumns: state.hiddenInvoiceColumns,
      selectedOrganization: state.selectedOrganization,
      selectedLocationId: state.selectedLocationId,
      language: state.language,
      tinkCode: state.tinkCode,
    };
    const serializedState = JSON.stringify(stateToPersist);
    localStorage.setItem(ZAYCARE_PERSISTENT_STATE, serializedState);
  } catch (e) {
    console.warn(e);
  }
}

function loadFromLocalStorage() {
  try {
    const serializedState = localStorage.getItem(ZAYCARE_PERSISTENT_STATE);
    if (serializedState === null) {
      return undefined;
    }
    const localStorageState: ILocalStorageState = JSON.parse(serializedState);
    return {
      ...getDefaultState(),
      ...localStorageState,
    };
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

export function resetState() {
  store.dispatch(action(getDefaultState()));
}

const getInitialState = (): IState => loadFromLocalStorage() ?? getDefaultState();

const store = createStore(reducer, getInitialState());

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
