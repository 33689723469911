// =============================================================================
// Theme Change works only in Production mode
// The theme provider needs the CSS file urls to inject those in the header as stylesheet links
// Create React App does not generate css files in Development mode
// 
// 1. Add the theme.less configuration in the craco.config.js
// 2. Add the theme.less in the dummy function __doNotCallMe_dummyToIncludeInBundles__()
// -----------------------------------------------------------------------------
// To test:
// npm i -g serve
// yarn build
// serve -s build
// =============================================================================

import React from 'react';
import { withSubscribe, publish } from 'react-pusu';
import { DEFAULT_THEME } from '../constants/theme';
import themeChanged from '../publications/theme-changed';
import useActionDispatch from '../redux/use-action-dispatch';
import useShallowSelector from '../redux/use-shallow-selector';
import detectTheme from '../utils/detect-theme';

const themeStylesheetLinkId = 'appTheme';

type TThemeProviderProps = {
  subscribe: any,
  theme: string,
  onChangeTheme: (theme: string) => void,
};

type TAssetManifest = {
  files: any
};

class ThemeProvider extends React.Component<TThemeProviderProps> {
  assetManifest: TAssetManifest | undefined

  constructor(props: TThemeProviderProps) {
    super(props);

    const { subscribe } = props;
    subscribe(themeChanged, this.applyTheme);

    const themeName = detectTheme() ?? this.props.theme;
    if (themeName) {
      this.applyTheme(themeName);
    }
  }

  applyTheme = (themeName: string) => {
    const { onChangeTheme } = this.props;

    const removeTheme = () => {
      const els = document.querySelectorAll(`link[id^='${themeStylesheetLinkId}']`);
      if (els?.length === 1) {
        els[0].remove();
      }
    };

    if (themeName === DEFAULT_THEME) {
      removeTheme();
    } else {
      const loadTheme = () => {
        const themeFileName = `theme.${themeName}.css`;
        if (themeFileName) {
          const stylesheetUrl = this.assetManifest?.files[themeFileName];
          if (stylesheetUrl) {
            onChangeTheme(themeName);
            const els = document.querySelectorAll(`link[id^='${themeStylesheetLinkId}']`);
            if (els?.length === 1) {
              els[0].setAttribute('href', stylesheetUrl);
            } else {
              const stylesheet = document.createElement('link');
              stylesheet.setAttribute('id', themeStylesheetLinkId);
              stylesheet.setAttribute('rel', 'stylesheet');
              stylesheet.setAttribute('href', stylesheetUrl);
              document.head.appendChild(stylesheet);
            }
          }
        }
      }

      if (this.assetManifest) {
        loadTheme();
      } else {
        fetch('/asset-manifest.json')
          .then(response => response.json())
          .then((assetManifest) => {
            this.assetManifest = assetManifest;
            loadTheme();
          });
      }
    }
  }

  __doNotCallMe_dummyToIncludeInBundles__() {
    // import all the available themes here
    import('../themes/storypark.less');
    import('../themes/light.less');
    import('../themes/dark.less');
  }

  componentDidUpdate(prevProps: TThemeProviderProps) {
    if (this.props.theme !== prevProps.theme) {
      this.applyTheme(this.props.theme);
    }
  }

  render() {
    const { children } = this.props;

    return children || null;
  }
}

export const applyTheme = (themeName: string): void => {
  publish(themeChanged, themeName);
}

type TThemeConnectProps = {
  children: (args: {
    theme: string,
    onChangeTheme: (theme: string) => void,
  }) => React.ReactElement,
}

const ThemeConnect: React.FC<TThemeConnectProps> = ({ children }) => {
  const theme = useShallowSelector(({ theme }) => theme);
  const dispatch = useActionDispatch();

  const onChangeTheme = (themeName: string) => {
    dispatch({ theme: themeName });
  };

  return (
    <React.Fragment>
      {children({ theme, onChangeTheme })}
    </React.Fragment>
  )
};

export default withSubscribe((props) => (
  <ThemeConnect>
    {({ theme, onChangeTheme }) => (
      <ThemeProvider {...props} theme={theme} onChangeTheme={onChangeTheme} />
    )}
  </ThemeConnect>
));
