import { useDispatch } from 'react-redux';
import action from './action';

const useActionDispatch = () => {
  const dispatch = useDispatch();
  return (payload: any) => {
    return dispatch(action(payload));
  };
}

export default useActionDispatch;
