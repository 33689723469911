import React, { FC, useEffect, useRef, useState } from 'react';
import { useSubscribe } from 'react-pusu';
import publication from '../../publications/app-overlay-spinner-queue';
import AppOverlaySpinnerView from './app-overlay-spinner-view';

const AppOverlaySpinnerProvider: FC = () => {
  const subscribe = useSubscribe();
  const [appOverlaySpinnerQueue, setAppOverlaySpinnerQueue] = useState<number[]>([]);
  const [visible, setVisible] = useState(false);
  const ref = useRef<{
    timer: NodeJS.Timeout | undefined,
    clearTimer: () => void,
    appOverlaySpinnerQueue: number[],
  }>({
    timer: undefined,
    clearTimer: () => {
      if (ref.current.timer) {
        clearTimeout(ref.current.timer);
      }
    },
    appOverlaySpinnerQueue: [],
  });

  useEffect(() => {
    subscribe(publication, (action) => {
      if (action === 'add') {
        setAppOverlaySpinnerQueue([...appOverlaySpinnerQueue, Date.now()]);
      } else {
        setAppOverlaySpinnerQueue([...appOverlaySpinnerQueue.slice(0, -1)]);
      }
    });
  }, []);

  useEffect(() => {
    if (appOverlaySpinnerQueue.length === 0) {
      ref.current.timer = setTimeout(() => {
        setVisible(false);
      }, 500);
    } else if (appOverlaySpinnerQueue.length > 0) {
      ref.current.clearTimer();
      setVisible(true);
    }
    return () => {
      ref.current.clearTimer();
    };
  }, [appOverlaySpinnerQueue.length]);

  return <AppOverlaySpinnerView visible={visible} />;
};

export default AppOverlaySpinnerProvider;
