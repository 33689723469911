import React, { FC, useEffect, useRef, useState } from 'react';
import { ConfigProvider, message, notification } from 'antd';
import antDLocaleEN from 'antd/lib/locale/en_US';
import antDLocaleNL from 'antd/lib/locale/nl_NL';
import i18n from '../services/i18n';

message.config({ duration: 8 });
notification.config({ duration: 8 });

const AntDConfigProvider: FC = ({ children }) => {
  const [locale, setLocate] = useState(i18n.language);

  const ref = useRef({
    onLanguageChange: (lng: string) => {
      setLocate(lng);
    },
    getAntDLocale: (lng: string) => {
      if (lng === 'nl') {
        return antDLocaleNL;
      }
      return antDLocaleEN;
    }
  });

  useEffect(() => {
    ref.current.onLanguageChange(i18n.language);

    i18n.on('languageChanged', ref.current.onLanguageChange);

    return () => {
      i18n.off('languageChanged', ref.current.onLanguageChange);
    };
  }, []);

  return (
    <ConfigProvider locale={ref.current.getAntDLocale(locale)}>
      {children}
    </ConfigProvider>
  );
};

export default AntDConfigProvider;
