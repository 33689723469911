import * as SchemaTypes from '../../generated/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Screen_Bootstrap_GetLoggedInUserQueryVariables = SchemaTypes.Exact<{ [key: string]: never; }>;


export type Screen_Bootstrap_GetLoggedInUserQuery = (
  { __typename?: 'Query' }
  & { getLoggedInUser?: SchemaTypes.Maybe<(
    { __typename?: 'Parent' }
    & Pick<SchemaTypes.Parent, 'id' | 'name' | 'authorizations' | 'featureIds' | 'emailVerified' | 'phoneNumber' | 'email' | 'profileImage'>
    & { phoneNumbers?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'PhoneNumber' }
      & Pick<SchemaTypes.PhoneNumber, 'phone' | 'type'>
    )>>>, rolesByOrganizations?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Organization' }
      & Pick<SchemaTypes.Organization, 'id' | 'name'>
      & { settings?: SchemaTypes.Maybe<(
        { __typename?: 'Setting' }
        & Pick<SchemaTypes.Setting, 'defaultProfileImage'>
      )> }
    )>>> }
  ) | (
    { __typename?: 'Teacher' }
    & Pick<SchemaTypes.Teacher, 'id' | 'name' | 'authorizations' | 'featureIds' | 'emailVerified' | 'phoneNumber' | 'email' | 'profileImage'>
    & { phoneNumbers?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'PhoneNumber' }
      & Pick<SchemaTypes.PhoneNumber, 'phone' | 'type'>
    )>>>, rolesByOrganizations?: SchemaTypes.Maybe<Array<SchemaTypes.Maybe<(
      { __typename?: 'Organization' }
      & Pick<SchemaTypes.Organization, 'id' | 'name'>
      & { settings?: SchemaTypes.Maybe<(
        { __typename?: 'Setting' }
        & Pick<SchemaTypes.Setting, 'defaultProfileImage'>
      )> }
    )>>> }
  )> }
);


export const Screen_Bootstrap_GetLoggedInUserDocument = gql`
    query Screen_Bootstrap_GetLoggedInUser {
  getLoggedInUser {
    id
    name
    phoneNumbers {
      phone
      type
    }
    authorizations
    featureIds
    emailVerified
    phoneNumber
    email
    profileImage
    rolesByOrganizations {
      id
      name
      settings {
        defaultProfileImage
      }
    }
  }
}
    `;

/**
 * __useScreen_Bootstrap_GetLoggedInUserQuery__
 *
 * To run a query within a React component, call `useScreen_Bootstrap_GetLoggedInUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useScreen_Bootstrap_GetLoggedInUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScreen_Bootstrap_GetLoggedInUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useScreen_Bootstrap_GetLoggedInUserQuery(baseOptions?: Apollo.QueryHookOptions<Screen_Bootstrap_GetLoggedInUserQuery, Screen_Bootstrap_GetLoggedInUserQueryVariables>) {
        return Apollo.useQuery<Screen_Bootstrap_GetLoggedInUserQuery, Screen_Bootstrap_GetLoggedInUserQueryVariables>(Screen_Bootstrap_GetLoggedInUserDocument, baseOptions);
      }
export function useScreen_Bootstrap_GetLoggedInUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Screen_Bootstrap_GetLoggedInUserQuery, Screen_Bootstrap_GetLoggedInUserQueryVariables>) {
          return Apollo.useLazyQuery<Screen_Bootstrap_GetLoggedInUserQuery, Screen_Bootstrap_GetLoggedInUserQueryVariables>(Screen_Bootstrap_GetLoggedInUserDocument, baseOptions);
        }
export type Screen_Bootstrap_GetLoggedInUserQueryHookResult = ReturnType<typeof useScreen_Bootstrap_GetLoggedInUserQuery>;
export type Screen_Bootstrap_GetLoggedInUserLazyQueryHookResult = ReturnType<typeof useScreen_Bootstrap_GetLoggedInUserLazyQuery>;
export type Screen_Bootstrap_GetLoggedInUserQueryResult = Apollo.QueryResult<Screen_Bootstrap_GetLoggedInUserQuery, Screen_Bootstrap_GetLoggedInUserQueryVariables>;