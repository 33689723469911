import { FC, useEffect } from 'react';
import useShallowSelector from '../../redux/use-shallow-selector';
import i18n, { supportedLanguages } from '../../services/i18n';
import getLanguage from '../../utils/get-language';

const I18NLanguageDetector: FC = () => {
  const userLanguage = useShallowSelector(({ language }) => language);

  useEffect(() => {
    const browserLanguage = getLanguage();
    if (userLanguage) {
      i18n.changeLanguage(userLanguage);
    } else if (browserLanguage
      && supportedLanguages.indexOf(browserLanguage) > -1) {
      i18n.changeLanguage(browserLanguage);
    }
  }, []);

  return null;
};

export default I18NLanguageDetector;
