import { useEffect, useRef } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18n from '../services/i18n';

const monthsI18nKeys = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const weekdaysI18nKeys = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const useConfigureMomentLocale = () => {
  const [t] = useTranslation();
  const ref = useRef({
    onLanguageChange: (lng: string) => {
      const months = monthsI18nKeys.map((it) => t(`common:Months.${it}`));
      const monthsShort = monthsI18nKeys.map((it) => t(`common:MonthsShort.${it}`));
      const weekdays = weekdaysI18nKeys.map((it) => t(`common:Weekdays.${it}`));
      const weekdaysShort = weekdaysI18nKeys.map((it) => t(`common:WeekdaysShort.${it}`));
      const languageSettings = {
        monthsParseExact: true,
        months,
        monthsShort,
        weekdaysParseExact: true,
        weekdays,
        weekdaysShort,
      };

      if (lng === 'nl') {
        moment.locale(lng, languageSettings);
      } else {
        moment.locale(lng);
      }
    }
  });

  useEffect(() => {
    ref.current.onLanguageChange(i18n.language);

    i18n.on('languageChanged', ref.current.onLanguageChange);

    return () => {
      i18n.off('languageChanged', ref.current.onLanguageChange);
    };
  }, []);
};

export default useConfigureMomentLocale;
