import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import OverlaySpinner from './components/app-overlay-spinner';
import ErrorBoundary from './components/error-boundary';
import reportWebVitals from './reportWebVitals';
import './services/i18n';
import './services/sentry';

ReactDOM.render(
  <ErrorBoundary>
    <React.Suspense fallback={<OverlaySpinner />}>
      <App />
    </React.Suspense>
  </ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(null);
