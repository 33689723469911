import { useState, useEffect } from 'react'
import Firebase from 'firebase';
import { FIREBASE_AUTH } from '../configs/firebase-config'

type TAuthProps = {
  isLoggedIn: boolean;
  loading: boolean;
  user?: Firebase.User;
}

const defaultAuth: TAuthProps = {
  isLoggedIn: false,
  loading: true,
  user: undefined,
}

const useAuth = (): TAuthProps => {
  const [authState, setAuthState] = useState(defaultAuth)

  useEffect(() => {
    const unregisterAuthObserver = FIREBASE_AUTH.onAuthStateChanged(user => {
      const isLoggedIn = !!user;
      return setAuthState({ user: user ?? undefined, loading: false, isLoggedIn });
    })
    return () => unregisterAuthObserver()
  }, []);

  return { ...authState }
}

export default useAuth;
