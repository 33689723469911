import { ApolloClient } from '@apollo/client';
import Firebase from 'firebase';
import { User } from '../../generated/types';
import getFbUserToken from '../../utils/auth/get-fb-user-token';
import {
  Screen_Bootstrap_GetLoggedInUserQuery as GetLoggedInUserQuery,
  Screen_Bootstrap_GetLoggedInUserQueryVariables as GetLoggedInUserQueryVariables,
  Screen_Bootstrap_GetLoggedInUserDocument as GetLoggedInUserDocument,
} from './gql';

const queryUser = async (
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>,
  fbUser: Firebase.User,
  organizationId?: string | null | undefined,
): Promise<User | null | undefined> => {
  if (fbUser) {
    await getFbUserToken(true); // to force refresh fb token
    const headers: { 'X-ORG-ID'?: string } = {};

    if (organizationId) {
      headers['X-ORG-ID'] = organizationId;
    }

    const result = await client.query<GetLoggedInUserQuery, GetLoggedInUserQueryVariables>({
      query: GetLoggedInUserDocument,
      context: {
        headers,
      },
    });
    const loggedInUser = result?.data?.getLoggedInUser;

    return Promise.resolve(loggedInUser);
  }
  return Promise.resolve(null);
};

export default queryUser;
